


































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Compilation} from '@/types';
import UBlock from '@/ui-lib/block/Block.vue';
import UTextField from '@/ui-lib/text-field/TextField.vue';
import {genre} from '@/store/modules/genre';

@Component({
  metaInfo() {
    return {
      title: 'Информация',
    };
  },
  components: {
    UBlock,
    UTextField,
  },
})
export default class CompilationInfoView extends Vue {
  @Prop({required: true})
  private compilation!: Compilation;

  private get genre() {
    return genre.context(this.$store).state.list.find((g) => g.id === this.compilation.genreId);
  }

  private get genreName(): string {
    return this.genre?.name || '';
  }
}
